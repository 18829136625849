import { Box, MenuItem, TableCell, TableRow } from '@mui/material';
import moment from 'moment-timezone';
import {
  Edit as EditIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlineOutlinedIcon,
  FileCopyOutlined as FileCopyIcon,
} from '@mui/icons-material';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { useMemo } from 'react';
import { formatDate } from '../../utils/formatDate';

type stores = { name: string; id: number };

const AffiliateListItem = ({
  reservationRow: affiliateRowData,
  editAffiliate,
  changeStatus,
  stores,
  copyAffiliateCode,
}: any) => {
  const { status, __typename, createdAt, stores: selectedStores, ...restData } = affiliateRowData;
  const userTimeZone = moment.tz.guess() || 'UTC';
  const sortedStores = useMemo(
    () =>
      affiliateRowData.stores.sort((a: stores, b: stores) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }),
    [affiliateRowData.stores],
  );

  return (
    <TableRow hover tabIndex={-1} key={affiliateRowData.srNo}>
      <TableCell>{affiliateRowData.affiliateName}</TableCell>
      <TableCell id={affiliateRowData.srNo} scope='affiliateRowData'>
        <span className='icon-text'>
          <FileCopyIcon onClick={() => copyAffiliateCode(affiliateRowData?.code)} />
          {affiliateRowData?.code ?? ''}
        </span>
      </TableCell>
      <TableCell>{affiliateRowData.discount}%</TableCell>
      <TableCell>{`${affiliateRowData.validFrom || 'N/A'} - ${
        affiliateRowData.validTo || 'N/A'
      }`}</TableCell>
      <TableCell>
        <Box
          component='span'
          className={
            affiliateRowData.codeExpired
              ? 'badge badge--red'
              : affiliateRowData.status
              ? 'badge badge--green'
              : 'badge badge--orange'
          }
        >
          {affiliateRowData.codeExpired
            ? 'Expired'
            : affiliateRowData.status
            ? 'Active'
            : 'Inactive'}
        </Box>
      </TableCell>
      <TableCell>{sortedStores.map((item: stores) => item.name).join(', ')}</TableCell>
      <TableCell>
        {affiliateRowData.createdAt && formatDate({ date: affiliateRowData.createdAt })}
      </TableCell>
      <TableCell align='right'>
        <CustomThreeDotsMenu labelId={affiliateRowData?.srNo}>
          <MenuItem
            onClick={() =>
              editAffiliate({
                ...restData,
                validFrom:
                  affiliateRowData.validFrom &&
                  moment(affiliateRowData.validFrom).format('MM/DD/YYYY'),
                validTo:
                  affiliateRowData.validTo && moment(affiliateRowData.validTo).format('MM/DD/YYYY'),
                stores: stores.map((item: any) => {
                  const result = selectedStores.find((data: any) => data.id == item.id);
                  const { name, id } = item;
                  return { name, storeId: id, value: result?.id ? true : false };
                }),
              })
            }
          >
            <EditIcon />
            Edit Affiliate
          </MenuItem>
          <MenuItem
            disabled={affiliateRowData?.codeExpired}
            onClick={() => changeStatus(affiliateRowData.id, affiliateRowData.status)}
          >
            {affiliateRowData.status ? (
              <>
                <CancelOutlinedIcon />
                Inactivate Affiliate
              </>
            ) : (
              <>
                <CheckCircleOutlineOutlinedIcon /> Activate Affiliate
              </>
            )}
          </MenuItem>
        </CustomThreeDotsMenu>
      </TableCell>
    </TableRow>
  );
};

export default AffiliateListItem;
