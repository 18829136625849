import { useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Box,
  Radio,
  RadioGroup,
  Alert,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as Images from '../../../Images';
import { useForm } from 'react-hook-form';
import CustomSubmitButton from '../../button/SubmitButton';
import { RenterModalContext } from '../RenterModalContext';
import NoRecordFound from '../../noRecordFound/NoRecordFound';
import { ISelectedPackages } from '../../../../models/reservation';
import { CustomTooltip } from '../../../CustomTooltip';
import { AddonsDetail } from './AddonsDetail';
import { yupResolver } from '@hookform/resolvers/yup';
import { addonsValidator } from '../validations/addRenterValidation';
import { useMutation } from '@apollo/client';
import { ADD_RENTER, EDIT_RENTER } from '../../../../graphql/api/reservation';
import { useAuth } from '../../../../hooks/useAuth';
import { Severity } from '../../../../enum/enumList';

interface IAddonsDetail {
  closeModal: () => void;
}

interface IForm {
  addons1: boolean;
  addons2: boolean;
  addons3: boolean;
  addons4: boolean;
}

interface IAddons {
  addonId: string | null;
  startDate: string | Date;
}

const addonIds = ['addon1Id', 'addon2Id', 'addon3Id', 'addon4Id'];

export const SubPackageDetail = ({ closeModal }: IAddonsDetail) => {
  const {
    handleBack,
    renterDetails,
    categories,
    reservationDetails,
    snackbarShowMessage,
    isFromDetails,
    updateRenterData,
  } = useContext(RenterModalContext);
  const { user } = useAuth();

  const addonsList = renterDetails?.selectedPackage;

  const [selectedSubPackages, setSelectedSubPackages] = useState(
    renterDetails?.selectedPackage ? renterDetails?.selectedPackage : null,
  );
  const packages = renterDetails?.packages ?? [];
  const selectedCategory = categories?.find(
    (item) => item?.id === renterDetails?.selectCategory?.packageId,
  )?.name;

  const storeId = user?.selectedStore;
  const reservationId = JSON.parse(sessionStorage.getItem('reservationId') as string);

  const [addRenter, { loading: addRenterLoading }] = useMutation(ADD_RENTER);
  const [updateRenter, { loading: updateRenterLoading }] = useMutation(EDIT_RENTER);

  const formInitalValue: IForm = {
    addons1: false,
    addons2: false,
    addons3: false,
    addons4: false,
  };

  const generateInitialFormValue = useMemo(() => {
    addonIds.forEach((addonKey, index) => {
      formInitalValue[`addons${index + 1}` as keyof IForm] =
        renterDetails?.selectedPackage?.addons &&
        renterDetails?.selectedPackage?.addons.some(
          (item) =>
            item?.addonsId ===
            renterDetails?.selectedPackage?.[addonKey as keyof ISelectedPackages],
        )
          ? true
          : false;
    });
    return formInitalValue;
  }, [renterDetails]);

  console.log(
    selectedSubPackages,
    generateInitialFormValue,
    'here are generateInitialFormValue --------->',
  );

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(addonsValidator),
    defaultValues: generateInitialFormValue,
  });

  const SinglePackageBox = useCallback(
    ({ details }: { details: ISelectedPackages }) => {
      return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            style={{ padding: '10px' }}
            className={`border-block border-block--pkg-addon border-block--fixed-width ${
              selectedSubPackages?.id === details?.id ? 'border-block--selected' : ''
            }`}
          >
            <Box className='border-block__img-block' component='label' htmlFor={details?.id}>
              <Radio
                name='radio-for-sub-packages'
                id={details?.id}
                value={details?.id}
                checked={selectedSubPackages ? selectedSubPackages?.id === details?.id : false}
              />
              <Box className='border-block__desc border-block__desc--border-brm'>
                {details?.description && (
                  <CustomTooltip
                    title={details?.description}
                    arrow
                    placement='top'
                    className='tooltip__list'
                  >
                    <InfoIcon fontSize='small' />
                  </CustomTooltip>
                )}
                {details?.name}
              </Box>
              <Box className='border-block__img border-block__img--sub-package'>
                <Box
                  component='img'
                  style={{ paddingTop: '10px' }}
                  src={details?.image ?? Images.renterSportPackage}
                  width={138}
                  height={138}
                  minWidth={138}
                />
                <Box className='border-block__price'>
                  {details?.price
                    ? `$${details?.price?.toFixed(2)}/${
                        reservationDetails.addReservation.reservationData.reservationType === 'D'
                          ? 'Day'
                          : 'Season'
                      }`
                    : 'N/A'}
                </Box>
              </Box>
            </Box>
            <Box className='border-block__addon-block'>
              <AddonsDetail
                addonDetails={details}
                selectedSubPackages={selectedSubPackages}
                control={control}
                renterDetails={renterDetails}
              />
            </Box>
          </Box>
        </Grid>
      );
    },
    [selectedSubPackages?.id, reservationDetails],
  );

  const handleFormSubmit = (value: any) => {
    if (!selectedSubPackages) {
      if (snackbarShowMessage)
        return snackbarShowMessage('Please select a package', Severity.Error);
    }

    const addons: IAddons[] = [];

    Object.keys(formInitalValue).forEach((item, index) => {
      if (value[item]) {
        addons.push({
          addonId:
            selectedSubPackages &&
            (selectedSubPackages[`addon${index + 1}Id` as keyof ISelectedPackages] as string),
          startDate: reservationDetails?.addReservation?.reservationData?.startDate || new Date(),
        });
      }
    });

    const renterInfo = {
      storeId,
      reservationId,
      addons,
      id: renterDetails?.renterId,
      firstName: renterDetails?.firstName,
      lastName: renterDetails?.lastName,
      gender: renterDetails?.gender,
      feet: renterDetails?.feet?.toString() || '0',
      inches: renterDetails?.inches?.toString() || '0',
      weight: (renterDetails?.weight && parseInt(renterDetails?.weight)) || 0,
      shoeSize: renterDetails?.shoeSize || '0',
      skierTypeId: renterDetails?.selectCategory?.skierTypeId || null,
      isSkiLength: renterDetails?.selectCategory?.isSkiLength || false,
      skiLengthPreference: renterDetails?.selectCategory?.skiLengthPreference,
      packageId: selectedSubPackages?.id,
      DOB: new Date(renterDetails?.dob as any).toISOString().split('T')[0],
      categoryId: renterDetails?.selectCategory?.packageId,
    };

    if (renterDetails?.renterId)
      updateRenter({
        variables: {
          editRenterArgs: renterInfo,
        },
        onCompleted(response) {
          if (snackbarShowMessage)
            snackbarShowMessage('Successfully updated package', Severity.Success);
          const data = { ...response?.updateRenter?.data };
          updateRenterData(data, renterDetails?.renterId);
          closeModal();
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
        },
      });
    else
      addRenter({
        variables: {
          addRenterArgs: {
            ...renterInfo,
            packageId: selectedSubPackages?.id,
            isFromDetail: isFromDetails,
          },
        },
        onCompleted(response) {
          if (snackbarShowMessage)
            snackbarShowMessage('Successfully added package', Severity.Success);
          const data = [{ ...response?.addRenter?.data }];
          updateRenterData(data);
          closeModal();
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
        },
      });
  };

  return (
    <>
      <DialogContent>
        <Grid
          container
          component='main'
          className='modal-lcol modal-lcol--border-none modal-lcol--mt'
          spacing={4}
        >
          <Box component='h3' className='block-heading block-heading--disp-fl'>
            {selectedCategory}
            <Box component='span' className='block-heading__rgt-col'>
              <strong>Note:</strong>Select your sub package by clicking on the image.
            </Box>
          </Box>
          {packages.length ? (
            <>
              <Box className='subpackage-alert'>
                <Alert severity='info' sx={{ margin: '0 0 14px' }}>
                  <strong>Note:</strong>Select your sub package by clicking on the image.
                </Alert>
              </Box>
              <RadioGroup
                row
                aria-labelledby='radio-for-sub-packages-label'
                name='radio-for-sub-packages'
                className='radio-block radio-block--inner-space'
                onChange={(e) => {
                  setSelectedSubPackages(
                    () =>
                      packages.filter((item: ISelectedPackages) => item?.id === e.target.value)[0],
                  );
                  setValue('addons1', false);
                  setValue('addons2', false);
                  setValue('addons3', false);
                  setValue('addons4', false);
                }}
              >
                <Grid container component='div' spacing={3}>
                  {packages.map((item: ISelectedPackages) => (
                    <SinglePackageBox key={item.id} details={item} />
                  ))}
                </Grid>
              </RadioGroup>
            </>
          ) : (
            <NoRecordFound text='No packages found' />
          )}
        </Grid>
      </DialogContent>

      <DialogActions className='modal__footer'>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button onClick={handleBack} sx={{ mr: 2 }} className='back-btn'>
            Previous
          </Button>

          <CustomSubmitButton
            onClick={handleSubmit(handleFormSubmit)}
            isLoading={addRenterLoading || updateRenterLoading}
          >
            Finish
          </CustomSubmitButton>
        </Box>
      </DialogActions>
    </>
  );
};
